<template>
  <div class="banner contacts-cover flex-centered column text-light">
    <img
      src="../../assets/images/contacts-cover.jpg"
      alt=""
      class="banner-img"
    />
    <div
      class="title relative half-width text-center pt-xl pb-md flex-centered"
    >
      <span class="high-light bold"> {{ $t("ContactUs.ContactUs") }} </span>
    </div>
    <p class="sub-title relative text-center">
      {{ $t("ContactUs.ContactUsDescription") }}
    </p>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.banner {
  box-sizing: border-box;
  height: 400px;
  background: url("../../assets/images/contacts-cover.jpg") center;
  background-size: cover;
  position: relative;
  z-index: -1;
}
.title,
.sub-title {
  z-index: 4;
}
.title {
  font-size: 48px;
  font-family: "Poppins";
}
.sub-title {
  font-size: 22px;
  font-weight: 300;
  color: white;
  width: 26%;
}
.light {
  font-weight: 300;
}
@media (max-width: 1200px) {
  .sub-title {
    width: 80%;
    font-size: 18px;
  }
  .title {
    font-size: 28px;
  }
}
</style>
