<template>
  <section class="wrapper-x wrapper-y flex-centered form-box-section">
    <div class="card form-card">
      <div class="title-xl text-primary bold poppins">
        {{ $t("ContactUs.ReachOut") }}
      </div>
      <p class="detail">
        {{ $t("ContactUs.ReachOutDescription") }}
      </p>
      <form class="form-group mt-lg pt-lg" v-on:submit.prevent="sendEmail()">
        <div class="input-box mb-lg">
          <label>{{ $t("ContactUs.Name") }}</label>
          <input
            type="text"
            :placeholder="$t('ContactUs.Email')"
            v-model="name"
            name="name"
            required
          />
        </div>
        <div class="input-box mb-lg">
          <label>{{ $t("ContactUs.Email") }}</label>
          <input
            type="email"
            :placeholder="$t('ContactUs.EmailPlaceholder')"
            v-model="email"
            name="email"
            required
          />
        </div>
        <div class="input-box mb-lg">
          <label>{{ $t("ContactUs.Message") }}</label>
          <textarea
            type="text"
            :placeholder="$t('ContactUs.MessagePlaceholder')"
            name="message"
            v-model="message"
            required
          />
        </div>
        <div class="button-area flex justify-end">
          <input
            type="submit"
            :value="$t('ContactUs.Send')"
            class="btn secondary"
          />
        </div>
      </form>
      <div id="myModal" class="modal">
        <!-- Modal content -->
        <div class="modal-content">
          <div class="modal-body flex-centered column">
            <i class="material-icons flex-centered done-icon">done</i>
            <p>
              {{ $t("ContactUs.SuccessMessage") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import emailjs from "emailjs-com";

export default {
  components: {},
  data() {
    return {
      name: "",
      email: "",
      message: ""
    };
  },
  methods: {
    completion() {
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function(event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
    },
    sendEmail() {
      try {
        emailjs.send(
          "service_9qg6tzk",
          "template_8i1n5gs",
          {
            name: this.name,
            email: this.email,
            message: this.message
          },
          "user_dLkbzOyuTM08huCTEdXXT"
        );
        this.completion();
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    }
  }
};
</script>
<style lang="scss">
.form-box-section {
  background: url("../../assets/images/wavyBg.svg") center no-repeat;
}
.form-card.card {
  width: 800px;
  padding: 40px 48px;
  .detail {
    font-size: 22px;
    color: #333333;
    font-weight: 300;
    width: 90%;
  }
  .input-box {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 4px;
      font-weight: 600;
      color: #333333;
      font-size: 16px;
    }
    input,
    textarea {
      &::placeholder {
        font-weight: normal;
        font-family: "Source Sans Pro", sans-serif;
      }
      border: 1px solid #d9d9d9 !important;
      background: transparent;
      padding: 12px 16px;
      border-radius: 4px;
    }
    textarea {
      height: 200px;
    }
  }
}
@media (max-width: 1200px) {
  .form-card.card {
    padding: 16px 22px;
  }
  .button-area {
    display: flex;
    justify-content: center;
  }
}
//modal
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  padding-top: 15%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    margin: auto;
    padding: 10px;
    width: 30%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .modal-body {
      padding: 2px 16px;
      text-align: center;
      font-size: 18px;
    }
  }
}
.done-icon {
  background: rgb(54, 190, 122);
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
</style>
