<template>
  <section class="location-list pb-xl flex-centered">
    <div class="location pa-lg flex-centered column text-center">
      <img src="../../assets/images/jpnLogo.png" alt="" />
      <div class="location-info">
        <div class="title-lg bold">{{ $t("ContactUs.JapanOffice") }}</div>
        <div class="title-lg py-sm">{{ $t("ContactUs.TokyoJapan") }}</div>
        <div class="phone">{{ $t("ContactUs.JapanPhone") }}</div>
      </div>
    </div>
    <div class="location pa-lg flex-centered column text-center">
      <img src="../../assets/images/npLogo.png" alt="" />
      <div class="location-info">
        <div class="title-lg bold">{{ $t("ContactUs.NepalOffice") }}</div>
        <div class="title-lg py-sm">{{ $t("ContactUs.KathmanduNepal") }}</div>
        <div class="phone">{{ $t("ContactUs.NepalPhone") }}</div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Location"
};
</script>
<style lang="scss">
.location {
  width: 300px;
  .phone {
    font-size: 16px;
  }
  img {
    height: 80px;
    width: 80px;
    object-fit: contain;
    object-position: center;
  }
  &:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
}
@media (max-width: 1200px) {
  .location-list {
    flex-direction: column;

    .location {
      flex-direction: column !important;

      &:first-child {
        border-right: unset;
      }
      flex-direction: row;
    }
  }
}
</style>
