<template>
  <div class="home">
    <ContactsCover />
    <FormBox />
    <Location />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import ContactsCover from "@/components/Contacts/ContactsCover";
import FormBox from "@/components/Contacts/FormBox";
import Location from "@/components/Contacts/Location";
import Footer from "@/components/Common/Footer";
export default {
  name: "Contact",
  components: { Footer, Location, FormBox, ContactsCover },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style scoped></style>
